const addToCart = data => {
    try {
        console.log("nbpix addToCart------------------------------------------")
        // console.log(data)
        if (window.nbpix) {
            const {
                productId,
                amount,
                currency,
                quantity,
                usdPrice
            } = data

            window.nbpix("event", "add_to_cart", {
                "nb_value":usdPrice
            });
        }
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        console.log("nbpix purchase------------------------------------------")
        console.log(data)
        if (window.nbpix) {
            const {
                transactionId,
                orderTotal,
                orderItems,
                paymentTotalWithUSD
            } = data

            window.nbpix("event", "complete_payment", {
                "nb_value":paymentTotalWithUSD?.amount
            });
        }
    } catch (e) {
        console.warn(e)
    }
}

export default {
    addToCart,
    purchase
}